import {
    axios
} from '@/utils/request'

// 项目实施典型抽查情况 

// 获取列表数据
export function _PorjectTypicalSpotChecksPage(params) {
    return axios({
        url: '/PorjectTypicalSpotChecks/Page',
        method: 'get',
        params,
    })
}

// 新增
export function _PorjectTypicalSpotChecksAdd(params) {
    return axios({
        url: '/PorjectTypicalSpotChecks/Add',
        method: 'post',
        data: params,
    })
}

// 删除
export function _PorjectTypicalSpotChecksDelete(params) {
    return axios({
        url: '/PorjectTypicalSpotChecks/Delete',
        method: 'post',
        data: params,
    })
}


// 编辑
export function _PorjectTypicalSpotChecksEdit(params) {
    return axios({
        url: '/PorjectTypicalSpotChecks/Edit',
        method: 'post',
        data: params,
    })
}



//详情
export function _PorjectTypicalSpotChecksInfo(params) {
    return axios({
        url: '/PorjectTypicalSpotChecks/Info',
        method: 'get',
        params,
    })
}


// excel导入
export function _PorjectTypicalSpotChecksImport(params) {
    return axios({
        url: '/PorjectTypicalSpotChecks/Import',
        method: 'get',
        params,
    })
}


// excel导出
export function _PorjectTypicalSpotChecksExport(params) {
    return axios({
        url: '/PorjectTypicalSpotChecks/Export',
        method: 'get',
        params,
        responseType: 'blob',
    })
}





